<template>
    <div id="login" >
      <!-- 登录 -->
        <div class="form" v-if="login">
          <form action="">
            <div class="form_top">
              <h3 >忘记密码</h3>
              
            </div>

            <div class="back" >
              <a href="" @click.prevent.stop='$router.back(-1)'>返回</a>
             </div>
            <!-- 登录框 -->
            <div class="form_ipts" >
              <div class="form_ipt">
                  <img class="form_icon1" src="../../public/images/用户.png">
                  <input type="tel" placeholder="11位手机号" name="tel" id="tel " v-model.trim="form.tel">
              </div>
              <div class="form_ipt">
                 <img class="form_icon2" src="../../public/images/安全.png">
                 <input type="text" placeholder="学号" name="stu_id" id="password" v-model.trim="form.stu_id">
              </div>

            </div>
            <!-- 登录注册 -->
            <div>

            <div class="login_res" >

              <div class="log">
                <button class="log_btn"  @click="submit" type="button" @keydown="submit">账号验证</button>
              </div>
            </div>

            <!-- 注册 -->

            </div>
            <!-- 登录 -->
          </form>
        </div>
<!-- 注册 -->
    <div class="form_recovery" v-else >
          <form action="">
            <div class="form_top">

               <h3>重置密码</h3>
                <!-- 阻止冒泡及默认事件 -->

            </div>

            <!-- 注册框 -->
            <div class="form_ipts_r" >

               <div class="form_ipt">
                  <img class="form_icon1" src="../../public/images/安全.png">
                  <input type="password" placeholder="新密码" name="pst" id="password " v-model="password">
              </div>

              <div class="form_ipt">
                 <img class="form_icon2" src="../../public/images/安全.png">
                 <input type="password" placeholder="重复新密码" name="rptPst" id="password" v-model="newPsd">
              </div>

            </div>

            <!-- 登录注册 -->
            <div>
            <!-- 注册 -->
            <div class="res_login">
              <div class="log">
                <button class="log_btn" @click="register" @keydown.enter="register" type="button">重置密码</button>
              </div>
            </div>
            </div>

        </form>
      </div>
 </div>
</template>

<script>
import { matchUser, recoveryPsd } from '../API/User.js'
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'login',
  data () {
    return {
      login: true,
      form: {
        tel: '',
        stu_id: ''
      },

      // tel: this.form.tel,
      password: '',

      newPsd: ''
    }
  },
  methods: {
    ...mapMutations(['upTokenInfo']),
    ...mapActions(['initUserInfo']),
    async submit (event) {
    //   this.form.tel = this.trim(this.form.tel)
    //   this.form.stu_id = this.trim(this.form.stu_id)
    //   this.psds.tel = thistrim()
    //   this.psds.password = this.psds.password.trim()
      // var regtel = /^((13[4-9])|(15([0-2]|[7-9]))|(18[2|3|4|7|8])|(178)|(147))[\d]{8}$/
      var regtel = /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/
      if (this.form.tel === ' ' || this.form.stu_id === '') {
        this.$message({
          showClose: true,
          message: '手机号或学号不能为空',
          type: 'error'
        })
      } else if (regtel.test(this.form.tel)) {
        const { data: res } = await matchUser(this.form)

        if (res.status === 0) {
          this.$message({
            showClose: true,
            message: '认证通过',
            type: 'success'
          })
          this.login = !this.login
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          })
        }
      } else {
        this.$message({
          showClose: true,
          message: '请输入正确的手机号',
          type: 'error'
        })
      }
      // event.preventDefault()
    },
    // 重置密码
    async register () {
      // 注册之后进行登录

      if (this.password === this.newPsd) {
        const obj = {
          tel: this.form.tel,
          password: this.password
        }
        const { data: res } = await recoveryPsd(obj)
        if (res.status === 0) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          })
          this.$router.replace({
            path: '/Home',
            name: 'Home'
          })
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          })
        }
      } else {
        this.$message({
          showClose: true,
          message: '新旧密码不一致',
          type: 'error'
        })
      }
    }

  },
  created () {
    // console.log(this.$route.query)
    if (this.$route.query.login === 'register') {
      this.login = false
    } else {
      this.login = true
    }
  }
}
</script>

<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
}


#login{
  width: 100%;
  position: relative;
  background-color: rgba(103, 189, 189, 0.6);
  height:100vh;
  background: url('../assets/images/background3.jpg') no-repeat;
  background-size: 100% 100%;
}

@media  all and (orientation: portrait) { /* 适配较小屏幕，例如手机 */
  #login{
    position: relative;
    background-color: rgba(103, 189, 189, 0.6);
    height:100vh;
    background: url('../assets/images/background3-phone.jpg') no-repeat;
    // background-size: auto 100%;
    background-size: 100% 100%; /* 调整为cover以填充容器，可能会裁剪图片 */
    background-position: center ; /* 确保图片居中 */
    overflow-x: auto; /* 允许水平滚动 */
    display: flex; /* 使用Flex布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
}
.form{
  width: 5.8333rem;
  // height: 6.3rem;
  height: auto;
  padding: 1.5rem,0;
  position: absolute;
  top: 5%;
  right: 20%;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius:20px ;
  .form_top{
    margin: 30px;
    h3{
      text-align: left;
    }
  }
  .back {
  margin: 30px;
  }

  .back h3 {
    text-align: left;
    color: blue; /* 添加蓝色文本 */
    text-decoration: underline; /* 添加下划线 */
  }
  .form_ipts{
    width: 80%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0);
    .form_ipt{
      display: flex;
      justify-content: space-around;
      margin: .5833rem 0 0;
      width: 100%;
      height: .5833rem;
      background-color: rgba(255, 255, 255, 0);
      .form_icon1{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;
        // background:url('../.././public/images/用户.png');
      }
       .form_icon2{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;

      }
      input{
        width:200px ;
        height: 35px;
        border: 0px;
        flex: 6;
        padding-left: 12px;
        outline: none;
        background-color: #F8F8F8;
      }
      input:focus{
        background-color: #fff;
      }
    }
  }
  // 注册样式
  .form_ipts_r{
    width: 80%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0);
    .form_ipt{
      display: flex;
      justify-content: space-around;
      margin: .5833rem 0 0;
      width: auto;
        height: 35px;
        background-color: rgba(255, 255, 255, 0);
      .form_icon1{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;
        // background:url('../.././public/images/用户.png');
      }
       .form_icon2{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;

      }
      input{
        width:200px ;
        height: 35px;
        border: 0px;
        flex: 6;
        padding-left: 12px;
        outline: none;
        background-color: #F8F8F8;
      }
      input:focus{
        background-color: #fff;
      }
    }
    // password

  }

  // 登录
  .login_res{
    width: 80%;
    display: flex;
    margin: .8333rem auto;
    justify-content: center;
    align-items: center;

    .res{
      a{
        padding: 0 .1667rem;
        color: #43dac3;
      }
      a:hover{
        color: #16a18d;
      }
    }
    .log{

      .log_btn{
            padding: 10px 25px;
            border: 0;
            background-color: #409EFF;
            border-radius: 20px;
            color: #fff;
            margin: 0 20px;
            cursor: pointer;
        }
        .log_btn:hover{
            background-color: #0092EE;
        }
    }
  }
  // 注册
  .res_login{
    width: 80%;
    display: flex;
    margin:.8rem auto;
    justify-content: center;
    align-items: center;
    .log{

      .log_btn{
            padding: 10px 25px;
            border: 0;
            background-color: #409EFF;
            border-radius: 20px;
            color: #fff;
            margin: 0 auto;
            cursor: pointer;
        }
        .log_btn:hover{
            background-color: #0092EE;
        }
    }
  }
}

.form_recovery{
  width: 5.8333rem;
  height: 5.3rem;
  padding: 1.5rem,0;
  position: absolute;
  top: 20%;
  right: 20%;
  background-color:#fff;
  border-radius:20px ;
  .form_top{
    margin: 30px;
    h3{
      text-align: left;
    }
  }
  .form_ipts{
    width: 80%;
    margin: 0 auto;

    .form_ipt{
      display: flex;
      justify-content: space-around;
      margin: .5833rem 0 0;
      width: 100%;
      height: .5833rem;
      background-color:#F8F8F8;
      .form_icon1{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;
        // background:url('../.././public/images/用户.png');
      }
       .form_icon2{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;

      }
      input{
        width:200px ;
        height: 35px;
        border: 0px;
        flex: 6;
        padding-left: 12px;
        outline: none;
        background-color: #F8F8F8;
      }
      input:focus{
        background-color: #fff;
      }
    }
  }
  // 注册样式
  .form_ipts_r{
    width: 80%;
    margin: 0 auto;

    .form_ipt{
      display: flex;
      justify-content: space-around;
      margin: .5833rem 0 0;
      width: 100%;
      height: .5833rem;
      background-color:#F8F8F8;
      .form_icon1{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;
        // background:url('../.././public/images/用户.png');
      }
       .form_icon2{
        display: inline-block;
        width: auto;
        height: 35px;
        flex: 1;

      }
      input{
        width:200px ;
        height: 35px;
        border: 0px;
        flex: 6;
        padding-left: 12px;
        outline: none;
        background-color: #F8F8F8;
      }
      input:focus{
        background-color: #fff;
      }
    }
    // password

  }

  // 登录
  .login_res{
    width: 80%;
    display: flex;
    margin: .8333rem auto;
    justify-content: center;
    align-items: center;

    .res{
      a{
        padding: 0 .1667rem;
        color: #43dac3;
      }
      a:hover{
        color: #16a18d;
      }
    }
    .log{

      .log_btn{
            padding: 10px 25px;
            border: 0;
            background-color: #409EFF;
            border-radius: 20px;
            color: #fff;
            margin: 0 20px;
            cursor: pointer;
        }
        .log_btn:hover{
            background-color: #0092EE;
        }
    }
  }
  // 注册
  .res_login{
    width: 80%;
    display: flex;
    margin:.8rem auto;
    justify-content: center;
    align-items: center;
    .log{

      .log_btn{
            padding: 10px 25px;
            border: 0;
            background-color: #409EFF;
            border-radius: 20px;
            color: #fff;
            margin: 0 auto;
            cursor: pointer;
        }
        .log_btn:hover{
            background-color: #0092EE;
        }
    }
  }
}
</style>
